<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link v-if="user[1] != 'DGIR'"
                     to="/Marche"> Marchés</router-link>
        <router-link v-else
                     to="/Marche"> Lettre de commande ></router-link>
        <!-- <router-link :to="'/Marche'"
                     v-else>
          Lettres de commande >
        </router-link> -->
        <router-link :to="`/Marche/detail/${this.$route.params.id}`">
          Détails ><!-- n°{{this.$route.params.id}} -->
        </router-link>
        <span>Modification {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Modification {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mx-auto">
      <div class="col-md-12 mx-auto">
        <div class="row">
          <Notif :notif="notif" />
        </div>
        <form class="accordion" 
              id="accordionExample">
          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingOne">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne">
                <th>Source {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</th>
              </tr>
            </thead>
            <div id="collapseOne"
                 class="collapse show"
                 aria-labelledby="headingOne"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Catégorie du programme <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select :options="[{value:'programme_entretien_routier',libelle:'Programme d\'Entretien Routier'},{value:'programme_investissement',libelle:'Programme d\'investissement'}]"
                              :reduce="categorie => categorie.value"
                              label="libelle"
                              ref="categorie_programme"
                              id="categorie_programme"
                              v-model="form.categorie_programme"
                              @input="selectProgramme"
                              placeholder="Choisir une catégorie"
                    >
                      <span v-if="form.errors().has('programme') && affiche.programme !== 'programme'"
                            v-text="form.errors().get('programme')"
                            class="errorMsg"> </span>
                    </v-select>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Programme <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select :options="programmes.donnees"
                              :reduce="programme => programme.id"
                              label="nom_programme"
                              ref="programme"
                              id="programme"
                              v-model="form.programme"
                              @input="getProgrameIdSelected"
                              placeholder="Choisir un programme"
                    >
                      <span slot="no-options"
                            @click="$refs.programme.open = false"
                            class="text-danger">
                        Aucun Programme
                      </span>
                    </v-select>
                    <span v-if="form.errors().has('programme') && affiche.programme !== 'programme'"
                          v-text="form.errors().get('programme')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Rubrique <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">     
                    <v-select :options="rubriquesList"
                              :reduce="rubrique => rubrique.id"
                              label="libelle"
                              ref="rubrique"
                              v-model="form.rubrique"
                              @input="getRubriqueIdSelected"
                              placeholder="Choisir une rubrique">
                      <span slot="no-options"
                            @click="$refs.rubrique.open = false"
                            class="text-danger">
                        Aucune Rubrique
                      </span>
                    </v-select>
                    <span v-if="form.errors().has('rubrique') && affiche.rubrique !== 'rubrique'"
                          v-text="form.errors().get('rubrique')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Activité <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="activitesList"
                      :reduce="activite => activite.id"
                      label="libelle"
                      ref="activite"
                      v-model="form.activite"
                      @input="removeSPan('activite'),getMontantActivite(form.activite)"
                      placeholder="Choisir une activité"
                    >
                      <span slot="no-options"
                            @click="$refs.activite.open = false"
                            class="text-danger">
                        Aucune Activité
                      </span>
                    </v-select>
                    <span v-if="form.errors().has('activite') && affiche.activite !== 'activite'"
                          v-text="form.errors().get('activite')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Montant définitif activité <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           disabled
                           class="form-control"
                           v-model="montant_definitif_activite" />
                  </div>
                </div>
              </div>
            </div> 
          </table>
          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingTwo">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo">
                <th>Informations {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</th>
              </tr>
            </thead>
            <div id="collapseTwo"
                 class="collapse"
                 aria-labelledby="headingTwo"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Montant initial {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}} <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           @input="addSpace('montant_initial_marche')"
                           @change="checkMontantActivite(form.activite)"
                           class="form-control"
                           v-on:click="removeSPan('cout_marche')"
                           v-model="montant_initial_marche" />
                    <span v-if="form.errors().has('cout_marche') && affiche.cout_marche !== 'cout_marche'"
                          v-text="form.errors().get('cout_marche')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Domiciliation Bancaire:<sup><span class="req-star">*</span></sup></label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           name="" 
                           id=""
                           v-model="form.domiciliation">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Références Ordre de service d'arrêt
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-model="form.reference_ordre_service_arret"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6><label for="">Date de l'Ordre de service d'arrêt</label></h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.date_ordre_service_arret">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Références Ordre de service de reprise des travaux
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-model="form.reference_ordre_service_reprise_travaux"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date de reprise des travaux selon l'ordre de service </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.date_reprise_travaux_selon_ordre_service">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date d'établissement du cautionnement AD</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.expiration_caution_ad">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date expiration de la caution AD</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="[
                        {libelle:'Date',valeur:'date'},
                        {libelle:'Remboursement intégrale de l\'avance démarrage',valeur:'Remboursement intégrale de l\'avance démarrage'},
                        {libelle:'Reception provisoire des travaux',valeur:'Reception provisoire des travaux'},
                        {libelle:'Reception définitif des travaux',valeur:'Reception définitif des travaux'}]"
                      :reduce="expiration => expiration.valeur"
                      label="libelle"
                      ref="expiration"
                      id="expiration"
                      @input="checkExpiration('expiration_ad','expiration_caution_ad_texte')"
                      v-model="expiration_ad"
                      placeholder="Choisir une expiration"
                    ></v-select>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id=""
                           v-if="expiration_ad=='date'" 
                           v-model="form.expiration_caution_ad_date">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Montant de l'Avance de démarrage</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control" 
                           name="" 
                           id="" 
                           @input="addSpace('montant_caution_ad')"
                           v-model="montant_caution_ad">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date d'établissement du cautionnement RG</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.date_etablissement_cautionnement_rg">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date expiration de la caution RG</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="[
                        {libelle:'Date',valeur:'date'},
                        {libelle:'Remboursement intégrale de l\'avance démarrage',valeur:'Remboursement intégrale de l\'avance démarrage'},
                        {libelle:'Reception provisoire des travaux',valeur:'Reception provisoire des travaux'},
                        {libelle:'Reception définitif des travaux',valeur:'Reception définitif des travaux'}]"
                      :reduce="expiration => expiration.valeur"
                      label="libelle"
                      ref="expiration"
                      id="expiration"
                      @input="checkExpiration('expiration_rg','expiration_caution_rg_texte')"
                      v-model="expiration_rg"
                      placeholder="Choisir une expiration"
                    ></v-select>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id=""
                           v-if="expiration_rg=='date'" 
                           v-model="form.expiration_caution_rg_date">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Montant de la retenue garantie</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control" 
                           name="" 
                           id="" 
                           @input="addSpace('montant_caution_rg')"
                           v-model="montant_caution_rg">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date d'établissement du cautionnement définitif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.date_etablissement_cautionnement_definitif">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date d'expiration du cautionnement définitif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="[
                        {libelle:'Date',valeur:'date'},
                        {libelle:'Remboursement intégrale de l\'avance démarrage',valeur:'Remboursement intégrale de l\'avance démarrage'},
                        {libelle:'Reception provisoire des travaux',valeur:'Reception provisoire des travaux'},
                        {libelle:'Reception définitif des travaux',valeur:'Reception définitif des travaux'}]"
                      :reduce="expiration => expiration.valeur"
                      label="libelle"
                      ref="expiration"
                      id="expiration"
                      @input="checkExpiration('expiration_definitif','expiration_caution_definitif_texte')"
                      v-model="expiration_definitif"
                      placeholder="Choisir une expiration "
                    ></v-select>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id=""
                           v-if="expiration_definitif=='date'"
                           v-model="form.expiration_caution_definitif_date">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Montant du cautionnement definitif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control" 
                           name="" 
                           id="" 
                           @input="addSpace('montant_caution_definitif')"
                           v-model="montant_caution_definitif">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date de fin des travaux</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.date_fin_travaux">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Maitrise d'ouvrage<sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text" 
                           class="form-control"
                           v-model="form.maitrise_ouvrage_deleguee">
                    <span v-if="form.errors().has('maitrise_ouvrage_deleguee') && affiche.maitrise_ouvrage_deleguee !== 'maitrise_ouvrage_deleguee'"
                          v-text="form.errors().get('maitrise_ouvrage_deleguee')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Maitrise d'ouvrage déléguée<sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="prestataires.donnees"
                      :reduce="programme => programme.id"
                      label="libelle"
                      ref="maitre_oeuvre"
                      v-model="form.maitre_oeuvre"
                      @input="removeSPan('maitre_oeuvre')"
                      placeholder="Choisir une maitrise d'ouvrage déléguée"
                    >
                      <span slot="no-options"
                            @click="$refs.maitre_oeuvre.open = false"
                            class="text-danger">
                        Aucune maitrise d'ouvrage déléguée
                      </span>
                    </v-select>
                    <span v-if="form.errors().has('maitre_oeuvre') && affiche.maitre_oeuvre !== 'maitre_oeuvre'"
                          v-text="form.errors().get('maitre_oeuvre')"
                          class="errorMsg"> </span>
                  </div>
                </div>  
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Libellé <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control"
                           v-on:click="removeSPan('libelle')"
                           v-model="form.libelle" />
                    <span v-if="form.errors().has('libelle') && affiche.libelle !== 'libelle'"
                          v-text="form.errors().get('libelle')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Numéro de marché/lettre de commande <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           name="numMarch"
                           class="form-control"
                           v-on:click="removeSPan('numero_marche')"
                           v-model="form.numero_marche" />
                    <span v-if="form.errors().has('numero_marche') && affiche.numero_marche !== 'numero_marche'"
                          v-text="form.errors().get('numero_marche')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Mission de suivi de contrôle des travaux
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="missionControls.donnees"
                      :reduce="missionControl => missionControl.id"
                      label="libelle"
                      ref="mission"
                      v-model="form.mission_suivi_controle"
                      @input="removeSPan('mission')"
                      placeholder="Choisir une mission de contrôle"
                    >
                      <span slot="no-options"
                            @click="$refs.mission.open = false"
                            class="text-danger">
                        Aucune mission de contrôle
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Date début <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           v-model="form.date_debut"
                           :ref="'date_debut'"
                           @change="monthDiff()"
                           v-on:click="removeSPan('date_debut')"
                           v-on:change="getDate()"
                           class="form-control" />
                    <span v-if="form.errors().has('date_debut') && affiche.date_debut !== 'date_debut'"
                          v-text="form.errors().get('date_debut')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Date fin <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           v-model="form.date_fin"
                           :ref="'date_fin'"
                           @change="monthDiff()"
                           v-on:click="removeSPan('date_fin')"
                           v-on:change="getDate()"
                           class="form-control" />
                    <span v-if="form.errors().has('date_fin') && affiche.date_fin !== 'date_fin'"
                          v-text="form.errors().get('date_fin')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Délai d'exécution {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}} (mois)<sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="number"
                           v-model="form.delai_execution_marche"
                           :ref="'delai_execution_marche'"
                           v-on:click="removeSPan('delai_execution_marche')"
                           v-on:change="getDate()"
                           class="form-control" 
                           disabled/>
                    <span v-if="form.errors().has('delai_execution_marche') && affiche.delai_execution_marche !== 'delai_execution_marche'"
                          v-text="form.errors().get('delai_execution_marche')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6> 
                      <label>
                        Département <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="departements.donnees"
                      :reduce="departement => departement.id"
                      label="libelle"
                      ref="departement"
                      id="departement"
                      v-model="form.departement"
                      multiple
                      placeholder="Choisir un département"
                    >
                      <span slot="no-options"
                            @click="$refs.programme.open = false"
                            class="text-danger">
                        Aucun Département
                      </span>
                    </v-select>
                    <span v-if="form.errors().has('delai_execution_marche') && affiche.delai_execution_marche !== 'delai_execution_marche'"
                          v-text="form.errors().get('delai_execution_marche')"
                          class="errorMsg"> </span>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Nature du réseau <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select :options="[{value:'TERRE',libelle:'Route en terre'},{value:'REVETUE',libelle:'Route revêtue'},{value:'AUTRE',libelle:'Autre'}]"
                              :reduce="nature => nature.id"
                              label="libelle"
                              ref="nature_reseau"
                              id="nature_reseau"
                              v-model="form.nature_reseau"
                              placeholder="Choisir une nature">

                      <span v-if="form.errors().has('departement') && affiche.departement !== 'departement'"
                            v-text="form.errors().get('departement')"
                            class="errorMsg"> </span>
                    </v-select>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Type d'entretien <sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select :options="[
                                {value:'PERIODIQUE',libelle:'Période'},
                                {value:'COURANT',libelle:'Courant'},
                                {value:'REHABILITATION',libelle:'Réhabilitation'},
                                {value:'TRAVAUX_NOEUFS',libelle:'Travaux neufs'},
                                {value:'AUTRE',libelle:'Autre'}]"
                              :reduce="type_entretien => type_entretien.id"
                              label="libelle"
                              ref="type_entretien"
                              id="type_entretien"
                              v-model="form.type_entretien"
                              placeholder="Choisir un type d'entretien">

                      <span v-if="form.errors().has('departement') && affiche.departement !== 'departement'"
                            v-text="form.errors().get('departement')"
                            class="errorMsg"> </span> 
                    </v-select> 
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Lot<sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.lot"> 
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>
                        Objet des travaux<sup><span class="req-star">*</span></sup>
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.objet">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Référence ANO</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.rf_ano">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Entreprise<sup><span class="req-star">*</span></sup></label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <v-select
                      :options="soustraitants.donnees"
                      :reduce="soustraitant => soustraitant.id"
                      label="libelle"
                      ref="entreprise"
                      v-model="form.entreprise"
                      @input="removeSPan('entreprise')"
                      placeholder="Choisir une entreprise"
                    >
                      <span slot="no-options"
                            @click="$refs.entreprise.open = false"
                            class="text-danger">
                        Aucune Entreprise
                      </span>
                    </v-select>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Références d'ordre de service de démarrage</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="text"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.references_ordre_service_demarrage">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date de signature de l'attributaire</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.date_sinature_attributaire">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date d'approbation {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.date_approbation_marche">
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date du démarrage effectif</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.date_demarrage_effectif">
                  </div>

                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label for="">Date de signature de l'autorité contractante</label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <input type="date"
                           class="form-control" 
                           name="" 
                           id="" 
                           v-model="form.date_signature_autorite_contractante">
                  </div>
                </div>
              </div>
            </div>
          </table>

          <table class="table col-md-8 mx-auto"
                 style="font-size: 0.6em;">
            <thead class="card-header"
                   id="headingFour">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour">
                <th>Ordonnancement</th>
              </tr>
            </thead>
            <div id="collapseFour"
                 class="collapse"
                 aria-labelledby="headingFour"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="col-md-12"
                     v-for="(item, index) in ordonnancements"
                     :key="index">
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label
                          :for="`data[${index}]montant_ordonnancement`">Montant ordonnancement
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             @input="addSpaceN(index)"
                             @change="verifierMontant()"
                             class="form-control"
                             v-model="item.montant_ordonnancement"
                             :name="`data[${index}]montant_ordonnancement`" />
                    </div>
                  
                  </div>
                  <div class="form-row row">
                    <div class="col-md-4 form-group">
                      <h6>
                        <label
                          :for="`data[${index}]annee_ordonnancement`">Année de l'ordonnancement
                        </label>
                      </h6>
                    </div>
                    <div class="col-md-4 form-group">
                      <input type="text"
                             class="form-control"
                             v-model="item.annee_ordonnancement"
                             :name="`data[${index}]annee_ordonnancement`"/>
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <button type="button"
                          class="btn btn-f-blue"
                          v-on:click="add_ordonnancement(),ordonnancements.push({montant_ordonnancement:'',annee_ordonnancement:''})">+</button>
                  <button type="button" 
                          class="btn btn-f-blue"
                          v-on:click="ordonnancements.splice(index, 1)">-</button>
                </div>
              </div>
            </div>
          </table>
          <table class="table col-md-8 mx-auto"
                 style="font-size:0.6em;">
            <thead class="card-header"
                   id="headingTwo">
              <tr class="bg-black"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree">
                <th>Fichiers à télécharger</th>
              </tr>
            </thead>
            <div id="collapseThree"
                 class="collapse"
                 aria-labelledby="headingThree"
                 data-parent="#accordionExample">
              <div class="mt-3">
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Cautionnement AD
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.cautionnement_ad }}
                      </label>
                      <input
                        type="file"
                        name="cautionnement_ad"
                        class="custom-file-input"
                        id="cautionnement_ad"
                        ref="cautionnement_ad"
                        placeholder="jpg jpeg png pdf xlsx csv doc"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                        v-on:click="removeSPan('cautionnement_ad')"
                        v-on:change="handleFileUpload('cautionnement_ad')"
                      />
                      <span class="listExtension">
                        Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                      </span>
                      <span v-if="form.errors().has('cautionnement_ad') && affiche.cautionnement_ad !== 'cautionnement_ad'"
                            v-text="form.errors().get('cautionnement_ad')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.cautionnement_ad"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Cautionnement définitif
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.cautionnement_definitif }}
                      </label>
                      <input
                        type="file"
                        name="cautionnement_definitif"
                        class="custom-file-input"
                        id="cautionnement_definitif"
                        ref="cautionnement_definitif"
                        placeholder="jpg jpeg png pdf xlsx csv doc"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                        v-on:click="removeSPan('cautionnement_definitif')"
                        v-on:change="handleFileUpload('cautionnement_definitif')"
                      />
                      <span class="listExtension">
                        Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                      </span>
                      <span v-if="form.errors().has('cautionnement_definitif') && affiche.cautionnement_definitif !== 'cautionnement_definitif'"
                            v-text="form.errors().get('cautionnement_definitif')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.cautionnement_definitif"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Cautionnement RG
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.cautionnement_rg }}
                      </label>
                      <input
                        type="file"
                        name="cautionnement_rg"
                        class="custom-file-input"
                        id="cautionnement_rg"
                        ref="cautionnement_rg"
                        placeholder="jpg jpeg png pdf xlsx csv doc"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                        v-on:click="removeSPan('cautionnement_rg')"
                        v-on:change="handleFileUpload('cautionnement_rg')"
                      />
                      <span class="listExtension">
                        Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                      </span>
                      <span v-if="form.errors().has('cautionnement_rg') && affiche.cautionnement_rg !== 'cautionnement_rg'"
                            v-text="form.errors().get('cautionnement_rg')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.cautionnement_rg"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Référence attestation réservation de crédit
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.reference_arc }}
                      </label>
                      <input
                        type="file"
                        name="reference_arc"
                        class="custom-file-input"
                        id="reference_arc"
                        ref="reference_arc"
                        placeholder="jpg jpeg png pdf xlsx csv doc"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                        v-on:click="removeSPan('reference_arc')"
                        v-on:change="handleFileUpload('reference_arc')"
                      />
                      <span class="listExtension">
                        Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                      </span>
                      <span v-if="form.errors().has('reference_arc') && affiche.reference_arc !== 'reference_arc'"
                            v-text="form.errors().get('reference_arc')"
                            class="errorMsg"> </span>
                    </div>

                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.loadrarc"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Ordre de service de démarrage
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.ordre_service_demarage }}
                      </label>
                      <input
                        type="file"
                        name="ordre_service_demarage"
                        class="custom-file-input"
                        id="ordre_service_demarage"
                        ref="ordre_service_demarage"
                        placeholder="jpg jpeg png pdf xlsx csv doc"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                        v-on:click="removeSPan('ordre_service_demarage')"
                        v-on:change="handleFileUpload('ordre_service_demarage')"
                      />
                      <span class="listExtension">
                        Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                      </span>
                      <span v-if="form.errors().has('ordre_service_demarage') && affiche.ordre_service_demarage !== 'ordre_service_demarage'"
                            v-text="form.errors().get('ordre_service_demarage')"
                            class="errorMsg"> </span>
                    </div>
                    <div class="form-group col-md-1 pt-2 my-auto">
                      <Loader v-show="loading.ordre_service_demarage"/>
                    </div>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Page de garde {{user[1]!="DGIR"?"du marché":"de la lettre de commande"}}
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.page_garde_marche }}
                      </label>
                      <input
                        type="file"
                        name="page_garde_marche"
                        class="custom-file-input"
                        id="page_garde_marche"
                        ref="page_garde_marche"
                        placeholder="jpg jpeg png pdf xlsx csv doc"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                        v-on:click="removeSPan('page_garde_marche')"
                        v-on:change="handleFileUpload('page_garde_marche')"
                      />
                      <span class="listExtension">
                        Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                      </span>
                      <span v-if="form.errors().has('page_garde_marche') && affiche.page_garde_marche !== 'page_garde_marche'"
                            v-text="form.errors().get('page_garde_marche')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.page_garde_marche"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >R.I.B. de la lettre de commande
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.lettre_commande_RIB }}
                      </label>
                      <input
                        type="file"
                        name="lettre_commande_RIB"
                        class="custom-file-input"
                        id="lettre_commande_RIB"
                        ref="lettre_commande_RIB"
                        placeholder="jpg jpeg png pdf xlsx csv doc"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                        v-on:click="removeSPan('lettre_commande_RIB')"
                        v-on:change="handleFileUpload('lettre_commande_RIB')"
                      />
                      <span class="listExtension">
                        Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                      </span>
                      <span v-if="form.errors().has('lettre_commande_RIB') && affiche.lettre_commande_RIB !== 'lettre_commande_RIB'"
                            v-text="form.errors().get('lettre_commande_RIB')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.lettre_commande_RIB"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Ordre de service d'arrêt
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.ordre_service_arret }}
                      </label>
                      <input
                        type="file"
                        name="ordre_service_arret"
                        class="custom-file-input"
                        id="ordre_service_arret"
                        ref="ordre_service_arret"
                        placeholder="jpg jpeg png pdf xlsx csv doc"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                        v-on:click="removeSPan('ordre_service_arret')"
                        v-on:change="handleFileUpload('ordre_service_arret')"
                      />
                      <span class="listExtension">
                        Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                      </span>
                      <span v-if="form.errors().has('ordre_service_arret') && affiche.ordre_service_arret !== 'ordre_service_arret'"
                            v-text="form.errors().get('ordre_service_arret')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.ordre_service_arret"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Ordre de service de reprise des travaux
                      </label>
                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.ordre_service_reprise_travaux }}
                      </label>
                      <input
                        type="file"
                        name="ordre_service_reprise_travaux"
                        class="custom-file-input"
                        id="ordre_service_reprise_travaux"
                        ref="ordre_service_reprise_travaux"
                        placeholder="jpg jpeg png pdf xlsx csv doc"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                        v-on:click="removeSPan('ordre_service_reprise_travaux')"
                        v-on:change="handleFileUpload('ordre_service_reprise_travaux')"
                      />
                      <span class="listExtension">
                        Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                      </span>
                      <span v-if="form.errors().has('ordre_service_reprise_travaux') && affiche.ordre_service_reprise_travaux !== 'ordre_service_reprise_travaux'"
                            v-text="form.errors().get('ordre_service_reprise_travaux')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.ordre_service_reprise_travaux"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label
                      >Avis de non Objection (ANO)
                      </label>

                    </h6>
                  </div>
                  <div class="col-md-4 form-group">
                    <div class="custom-file">
                      <label class="custom-file-label">
                        {{ inputFiles.avis_non_objection }}
                      </label>
                      <input
                        type="file"
                        name="avis_non_objection"
                        class="custom-file-input"
                        id="avis_non_objection"
                        ref="avis_non_objection"
                        placeholder="jpg jpeg png pdf xlsx csv doc"
                        accept=".jpg,.jpeg,.png,.pdf,.xlsx"
                        v-on:click="removeSPan('avis_non_objection')"
                        v-on:change="handleFileUpload('avis_non_objection')"
                      />
                      <span class="listExtension">
                        Format :
                        <span class="fileExtentions">{{ CreateEspaceBetweenFileExtentions() }} </span>
                      </span>
                      <span v-if="form.errors().has('avis_non_objection') && affiche.avis_non_objection !== 'avis_non_objection'"
                            v-text="form.errors().get('avis_non_objection')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                  <div class="form-group col-md-1 pt-2 my-auto">
                    <Loader v-show="loading.avis_non_objection"/>
                  </div>
                </div>
                <div class="form-row row">
                  <div class="col-md-4 form-group">
                    <h6>
                      <label>Description</label>
                    </h6>
                  </div>
                  <div class="form-group col-md-4">
                    <div class="form-group">
                      <textarea class="form-control"
                                v-model="form.description"
                                v-on:click="removeSPan('description')"
                                rows="4"> </textarea>
                      <span v-if="form.errors().has('description') && affiche.description !== 'description'"
                            v-text="form.errors().get('description')"
                            class="errorMsg"> </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </table>
          <div class="form-row row mt-4">
            <div class="col-md-8 offset-sm-5">
              <div class="form-group">
                <button type="button"
                        class="btn btn-f-blue"
                        @click="submit"><i class="flaticon flaticon-floppy-disk mr"></i>&nbsp; &nbsp;Enregistrer</button>

                <button type="button"
                        class="btn btn-f-blue ml-2"
                        data-toggle="modal"
                        data-target="#exampleModal"><i class="flaticon flaticon-cancel"></i> &nbsp; &nbsp;Annuler</button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- modal box -->
      <div class="modal fade"
           id="exampleModal"
           tabindex="-1"
           role="dialog"
           aria-labelledby="exampleModalLabel"
           aria-hidden="true">
        <div class="modal-dialog"
             role="document">
          <div class="modal-content">
            <div class="modal-header ">
              <h5 class="modal-title text-uppercase"
                  id="exampleModalLabel">Annulation</h5>
              <button type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Cette action est irréversible, êtes-vous sure de vouloir annuler ?</p>
            </div>
            <div class="modal-footer">
              <button type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal">Non</button>
              <button type="button"
                      @click="goBack()"
                      data-dismiss="modal"
                      class="btn btn-primary">Oui</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>

#reference_arc::before{
  content: "Pick file";
  position: absolute;
  z-index: 2;
  display: block;
  background-color: #eee;
  width: 80px;
}
.errorMsg {
  color: red;
  font-size: 10px;
  margin: 0;
  padding: 0;
}
.errormsg {
  color: red;
  font-size: 12px;
  margin: 0;
  padding: 0;
}
.successmsg {
  color: green;
  font-size: 12px;
  margin: 0;
  padding: 0;
}
span.displayname {
  color: #333;
  z-index: 10;
  font-size: 12px;
  padding: 10px;
  display: block;
  position: absolute;
  top: 0;
}
.listExtension {
  font-size: 0.7em;
}
.fileExtentions {
  color: #0d6efd;
} 
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
span[slot="no-options"].text-danger{
  font-size: 0.7em;
}
form select.form-control option{
  font-size: 1.5em;
}
form select.form-control,.v-select, input.vs__search,input.vs__search:focus{
  font-size: 0.8em;
}
form input, form textarea{
  height: fit-content;
}
div.vs__dropdown-toggle,form select.form-control{
  height: 4.9vh;
  overflow-y: hidden;
}
@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: fit-content;
  }
}

</style>
<script>
import form from "vuejs-form"
import Loader from "@/components/shared/Spinner"
import Notif from "@/components/shared/Toast"
import { mapActions, mapGetters, mapMutations } from "vuex"
import utils from "@/assets/js/utils"
import VSelect from "vue-select"
import "vue-select/dist/vue-select.css"

export default {
  name: "MrchAdd",
  components: {
    Loader,
    VSelect,
    Notif
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    ordonnancements: [
      {
        montant_ordonnancement: "",
        annee_ordonnancement: "",
       
      },
    ],
    expiration_ad:"date",
    montant_caution_ad:"",
    montant_caution_rg:"",
    montant_caution_definitif:"",
    expiration_rg:"date",
    expiration_definitif:"date",
    form: form({
      maitre_oeuvre: "",
      maitrise_ouvrage_deleguee: "",
      libelle: "",
      numero_marche: "",
      programme: "",
      rubrique: "",
      activite: "",
      domiciliation: "",
      page_garde_marche: "",
      lettre_commande_RIB: "",
      ordre_service_arret:"",
      ordre_service_demarage:"",
      ordre_service_reprise_travaux:"",
      avis_non_objection:"",
      departement: [],
      date_debut: "",
      date_fin: "",
      reference_arc: "",
      reference_af: "",
      demande_information:"",
      nature_reseau:"",
      type_entretien:"",
      lot:"",
      categorie_programme:"",
      objet:"",
      numero_marche_ou_lettre_commande:"",
      montant_initial_marche:"",
      montant_caution_ad:"",
      montant_caution_rg:"",
      montant_caution_definitf:"",
      conformite_ad:"",
      conformite_rg:"",
      conformite_definitf:"",
      // montant_total:"",
      entreprise:"",
      nom_bureau_controle:"",
      date_sinature_attributaire:"",
      date_signature_autorite_contractante:"",
      date_approbation_marche:"",
      delai_execution_marche:"",
      date_demarrage_effectif:"",
      cautionnement_ad:"",
      date_etablissement_cautionnement_ad:"",
      expiration_caution_ad_date:"",
      expiration_caution_ad_texte:"",
      cautionnement_definitif:"",
      date_etablissement_cautionnement_definitif:"",
      expiration_caution_definitif_date:"",
      date_etablissement_cautionnement_rg:"",
      cautionnement_rg:"",
      expiration_caution_rg_date:"",
      expiration_caution_rg_texte:"",
      mission_suivi_controle:"",
      description: "",
      references_ordre_service_demarrage:"",
      reference_ordre_service_arret:"",
      reference_ordre_service_reprise_travaux:"",
      rf_ano:"",
      expiration_caution_definitif_texte:"",
      date_reprise_travaux_selon_ordre_service:"",
      ordonnancements:[],
      
      // ordonnancements:[
      //   {
      //     montant_ordonnancement:35800000,
      //     annee_ordonnancement:2023,
      //   },
      //   {
      //     montant_ordonnancement:83500000,
      //     annee_ordonnancement:2022,
      //   },]
    }),
    countval: 0,
    uploadfile: "reference_arc",
    rubriquesList: [],
    programmeList: [],
    activitesList: [],
    reference_arc: "",
    reference_af: "",
    affiche: {
      maitre_oeuvre: "maitre_oeuvre:true",
      maitrise_ouvrage_deleguee: "",
      libelle: "libelle:true",
      numero_marche: "numero_marche:true",
      programme: "programme:true",
      rubrique: "rubrique:true",
      activite: "activite:true",
      cout_marche: "cout_marche:true",
      departement: "departement:true",
      domiciliation: "",
      page_garde_marche: "",
      lettre_commande_RIB: "",
      // ordre_service_demarage:"",
      // ordre_service_reprise_travaux:"",
      // avis_non_objection:"",
      date_debut: "date_debut:true",
      date_fin: "date_fin:true",
      reference_arc: "reference_arc:true",
      reference_af: "reference_af:true",
      description: "description:true",
      annee_ordonnancement:"annee_ordonnancement:true",
      // date_reprise_travaux_selon_ordre_service:"date_reprise_travaux_selon_ordre_service:true",
      // reference_ordre_service_arret:"reference_ordre_service_arret:true",
      // montant_definitif_activite:"montant_definitif_activite:true",
      // expiration_cautionnement_definitif:"expiration_cautionnement_definitif:true",
      // reference_ordre_service_reprise_travaux:"reference_ordre_service_reprise_travaux:true",
      // montant_ordonnancement:"montant_ordonnancement:true",
      // expiration_cautionnement_definitif_date:"expiration_cautionnement_definitif_date:true",
      // ordre_service_arret:"",
    },
    user:"",
    montant_initial_marche: "",
    montant_total:"",
    montant_definitif_activite:"",
    montant_ordonnancement:"",
    loadrarc: false,
    loadraf: false,
    reps: false,
    is_inferieur:true,
    verifMontant:false,
    banks:[],
    inputFiles: { 
      reference_arc: "", 
      reference_af: "", 
      cautionnement_ad: "", 
      cautionnement_definitif: "",
      page_garde_marche: "",
      // lettre_commande_RIB: "",
      // cautionnement_rg: "",
      // avis_non_objection:"",
      // ordre_service_demarage:"",
      // ordre_service_reprise_travaux:"",
      // ordre_service_arret:"",
    },
    loading:{
      cautionnement_ad:false,
      cautionnement_rg:false,
      cautionnement_definitif:false,
      page_garde_marche:false,
      lettre_commande_RIB:false,
      loadrarc:false
    },
  }),
  watch: {
    // ["form.data"]: {
    //   deep: true,
    //   immediate: false,
    //   handler: "onFormChange"
    // },
    marche() {
      if (this.marche) {
        this.form.id=this.$route.params.id
        this.form.libelle = this.marche.libelle
        this.form.numero_marche = this.marche.numero_marche
        this.form.programme = this.marche.activite.rubrique.programme.id
        this.form.rubrique = this.marche.activite.rubrique.id
        this.form.domiciliation = this.marche.domiciliation
        this.form.maitrise_ouvrage_deleguee = this.marche.maitrise_ouvrage_deleguee
        if (!isNaN(Math.round(Number(this.marche.montant_initial_marche)))) {
          this.form.montant_initial_marche = this.marche.montant_initial_marche.split(".")
          this.montant_initial_marche= this.form.montant_initial_marche[0]
          this.addSpace("montant_initial_marche")          
        }else{
          this.form.montant_initial_marche = 0
          this.cout_marche= 0
        }
        if (!isNaN(Math.round(Number(this.marche.montant_total)))) {
          if (this.marche.montant_total.toString().includes(".")) {
            this.form.montant_total = this.marche.montant_total.toString().split(".")
            this.montant_total = this.form.montant_total[0]
          }else{
            this.montant_total = this.marche.montant_total.toString()
          }
          this.addSpace("montant_total")
        }else{
          this.form.montant_total = 0
        }
        if (this.marche.departement.length>0) {
          this.marche.departement.forEach(departement => {
            this.form.departement.push(departement.id) 
          })
        }
        this.form.maitre_oeuvre = this.marche.maitre_oeuvre
        this.form.reference_arc = this.marche.reference_arc
        this.form.cautionnement_ad = this.marche.cautionnement_ad
        this.form.cautionnement_definitif = this.marche.cautionnement_definitif
        this.form.cautionnement_rg = this.marche.cautionnement_rg
        this.form.page_garde_marche = this.marche.page_garde_marche
        this.form.lettre_commande_RIB = this.marche.lettre_commande_RIB
        this.ordonnancements = this.marche.ordonnancements
        // console.log(this.ordonnancements)
        // this.form.date_reprise_travaux_selon_ordre_service = this.marche.date_reprise_travaux_selon_ordre_service
        // this.form.reference_ordre_service_arret = this.marche.reference_ordre_service_arret
        // this.form.montant_definitif_activite = this.marche.montant_definitif_activite
        // this.form.expiration_cautionnement_definitif = this.marche.expiration_cautionnement_definitif
        // this.form.expiration_cautionnement_definitif_date = this.marche.expiration_cautionnement_definitif_date
        // this.form.reference_ordre_service_reprise_travaux = this.marche.reference_ordre_service_reprise_travaux
        // this.form.avis_non_objection = this.marche.avis_non_objection
        // this.form.ordre_service_reprise_travaux = this.marche.ordre_service_reprise_travaux
        // this.form.ordre_service_demarage = this.marche.ordre_service_demarage
        // this.form.ordre_service_arret = this.marche.ordre_service_arret
        // this.form.ordonnancements.montant_ordonnancement = this.marche.montant_ordonnancement
        // this.form.reference_af = this.marche.reference_af
        this.form.description = this.marche.description
        
        if (this.marche.reference_arc!=null && this.marche.reference_arc!="") {
          this.getFileName("reference_arc")
          console.log(this.inputFiles.reference_arc)
        }

        if (this.marche.cautionnement_ad!=null && this.marche.cautionnement_ad!="") {
          this.getFileName("cautionnement_ad")
          console.log(this.inputFiles.cautionnement_ad)
        }

        if (this.marche.cautionnement_definitif!=null && this.marche.cautionnement_definitif!="") {
          this.getFileName("cautionnement_definitif")
          console.log(this.inputFiles.cautionnement_definitif)
        }

        if (this.marche.cautionnement_rg!=null && this.marche.cautionnement_rg!="") {
          this.getFileName("cautionnement_rg")
          console.log(this.inputFiles.cautionnement_rg)
        }
        if (this.marche.page_garde_marche!=null && this.marche.page_garde_marche!="") {
          this.getFileName("page_garde_marche")
          console.log(this.inputFiles.page_garde_marche)
        }
        if (this.marche.lettre_commande_RIB!=null && this.marche.lettre_commande_RIB!="") {
          this.getFileName("lettre_commande_RIB")
          console.log(this.inputFiles.lettre_commande_RIB)
        }
        // if (this.marche.avis_non_objection!=null && this.marche.avis_non_objection!="") {
        //   this.getFileName("avis_non_objection")
        //   console.log(this.inputFiles.avis_non_objection)
        // }
        // if (this.marche.ordre_service_reprise_travaux!=null && this.marche.ordre_service_reprise_travaux!="") {
        //   this.getFileName("ordre_service_reprise_travaux")
        //   console.log(this.inputFiles.ordre_service_reprise_travaux)
        // }
        // if (this.marche.ordre_service_arret!=null && this.marche.ordre_service_arret!="") {
        //   this.getFileName("ordre_service_arret")
        //   console.log(this.inputFiles.ordre_service_arret)
        // }
        // if (this.marche.ordre_service_demarage!=null && this.marche.ordre_service_demarage!="") {
        //   this.getFileName("ordre_service_demarage")
        //   console.log(this.inputFiles.ordre_service_demarage)
        // }
        this.form.demande_information=this.marche.demande_information
        this.form.nature_reseau=this.marche.nature_reseau
        this.form.type_entretien=this.marche.type_entretien
        this.form.lot=this.marche.lot
        this.form.categorie_programme=this.marche.activite.rubrique.programme.categorie_programme
        this.form.objet=this.marche.objet
        this.form.numero_marche_ou_lettre_commande=this.marche.numero_marche_ou_lettre_commande
        this.form.rf_ano=this.marche.rf_ano
        this.form.entreprise=this.marche.entreprise.id
        this.form.nom_bureau_controle=this.marche.nom_bureau_controle
        
        this.verifyDate("date_sinature_attributaire")
        this.verifyDate("date_signature_autorite_contractante")
        this.verifyDate("date_approbation_marche")
        this.verifyDate("date_etablissement_cautionnement_ad")
        this.verifyDate("date_expiration_cautionnement_ad")
        this.verifyDate("date_etablissement_cautionnement_definitif")
        this.verifyDate("date_expiration_cautionnement_definitif")
        this.verifyDate("date_etablissement_cautionnement_rg")
        this.verifyDate("date_expiration_cautionnement_rg")
        this.verifyDate("date_debut")
        this.verifyDate("date_fin")
        this.verifyDate("date_demarrage_effectif")
        // this.verifyDate("expiration_cautionnement_definitif_date")

        this.form.delai_execution_marche=this.marche.delai_execution_marche
        this.form.references_ordre_service_demarrage=this.marche.references_ordre_service_demarrage
        this.form.mission_suivi_controle=this.marche.id_mission_suivi_controle
        // donnees historiques
        this.form.entreprise_historique= this.marche.entreprise_historique
        this.form.mission_controle_historique=this.marche.mission_controle_historique
        this.form.maitrise_oeuvre_historique=this.marche.maitrise_oeuvre_historique
        // this.reference_af = this.marche.reference_af.split("/")
        this.inputFiles.reference_arc = this.reference_arc[this.reference_arc.length - 1]
        this.inputFiles.reference_af = this.reference_af[this.reference_arc.length - 1]
        this.getRubList(this.marche.activite.rubrique.programme.id)
        this.form.activite = this.marche.activite.id
        // this.getActList(this.marche.activite.rubrique.id)
      }
    },
    banques(){
      if (this.banques.donnees) {
        this.banks=[]
        this.banques.donnees.forEach(banque => {
          if (banque.compte_bancaires.length>0) {
            this.banks.push(banque)
          }
        })
        // console.log(this.banks)
      }
    },
    marcherrors() {
      if (this.marcherrors) {
        
        this.notif.message = this.marcherrors
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErors("")
          }.bind(this),
          3000
        )
      }
    },
    files() {
      if (this.files && this.files["url"]) {
        this.form[this.uploadfile] = process.env.VUE_APP_UPLOAD + this.files["url"]
        this.$refs["reference_arc"].disabled = false
        
      }
    },
    filerrors() {
      if (this.filerrors) {
        this.loadrarc = false
        this.loadraf = false
        // this.$refs["reference_af"].disabled = false
        this.$refs["reference_arc"].disabled = false
      }
    },
    programmes() {
      this.programmeList = this.programmes
    },
    updateMarches() {
      if (this.updateMarches == 200) {
        this.notif.message = "Le marché a été modifié"
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setupMarches("")
            this.$router.push({ name: "Marche" })
          }.bind(this),
          3000
        )
      }
    },
    assignProgramme() {
      this.rubriquesList = []
      this.rubriquesList = this.assignProgramme.programme.rubriques
      if (this.$route.params.id) {
        this.getActList(this.marche.activite.rubrique.id)
      }
    }
  },
  computed: {
    ...mapGetters(["programmes", "assignProgramme", "files", "filerrors", "errors", "departements", "singleMarches", "updateMarches", "banques", "marcherrors", "prestataires", "marche", "missionControls", "soustraitants", "marchpartenaires"])
  },
  mounted() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.ListProgrammes()
    this.loadBanques()
    this.getDepartements()
    this.getPrestataires()
    this.getSousTraitants()
    this.getMissionControls()
    this.getMarche(this.$route.params.id)
  },
  methods: {
    ...mapActions(["ListProgrammes", "saveFile", "getDepartements", "getMarche", "getProgramme", "getMissionControls", "getSousTraitants", "getPrestataires", "updateMarche","loadBanques",]),
    ...mapMutations(["setupMarches", "setErors"]),
    verifyDate(key) {
      if (this.marche[key]!=null && this.marche[key]!="") {
        if (this.marche[key].includes(" ")) {
          this.form[key]=this.marche[key].split(" ")
          this.form[key]=this.form[key][0]
        }else{
          this.form[key]=this.marche[key]
        }
      }
    },
    // recreate(value){
    //   if (value !=0 && value !="") {
    //     var splinter = value.split(" ")
    //     this.form.montant_initial_marche = ""
    //     for (let index = 0; index < splinter.length; index++) {
    //       this.form.montant_initial_marche += splinter[index]
    //     }
    //   }
    // },
    getFileName(key){
      this.inputFiles[key] = this.form[key].split("/")
      this.inputFiles[key] = this.inputFiles[key][this.inputFiles[key].length-1]
      this[key] = this.inputFiles[key]
    },
    monthDiff() {
      var debut = this.form.date_debut.split("-"),
          fin = this.form.date_fin.split("-")
      
      if ((this.form.date_debut!="" && this.form.date_debut!="0000-00-00") && (this.form.date_fin!="" && this.form.date_fin!="0000-00-00") ) {
        debut = new Date(debut[0],debut[1],debut[2]) 
        fin = new Date(fin[0],fin[1],fin[2]) 
        var months
        months = (fin.getFullYear() - debut.getFullYear()) * 12
        months -= debut.getMonth()
        months += fin.getMonth()
        this.form.delai_execution_marche = months
      }  
    },
    add_ordonnancement(){
      console.log(this.ordonnancements)
    },
    checkExpiration(key,formKey){
      console.log(this[key])
      if (this[key]!="date") {
        this.form[formKey]=this[key]
      }
    },
    submit() {
      if(this.ordonnancements.length == 1 && this.ordonnancements[0].annee_ordonnancement== "" ){
        this.ordonnancements[0].montant_ordonnancement = this.form.montant_initial_marche
        this.ordonnancements[0].annee_ordonnancement = this.form.date_debut.split("-")
        this.ordonnancements[0].annee_ordonnancement = this.ordonnancements[0].annee_ordonnancement[0]
      }
      if(!this.verifMontant){
        this.form.ordonnancements=this.ordonnancements
        this.form.validate()
        if (this.is_inferieur) {
          this.updateMarche({ data: this.form.data, id_marche: this.marche.id })  
        }else{
          this.notif.message = "Le montant du marché ne doit pas excéder le montant de l'activité"
          this.notif.type = "toast-danger"
          this.notif.activated = "activated"
          setTimeout(
            function() {
              this.notif.activated = ""
            }.bind(this),
            3000
          )
        }
      }else{
        this.notif.message = "le montant total des ordonnancements ne doit pas dépasser celui du marché"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        this.loadrarc = false

        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    // New addSpace
    addSpaceN(key) {
      if (this.ordonnancements[key]) {
        var chaine = ""
        var splinter = this.ordonnancements[key].montant_ordonnancement.split("")
        this.ordonnancements[key].montant_ordonnancement = ""

        if (/[0-9]/.test(this.ordonnancements[key].montant_ordonnancement) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this.ordonnancements[key].montant_ordonnancement)) {
          for (let index = 0; index < splinter.length; index++) {
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
              splinter[index] = ""
            }
            chaine += splinter[index]
          }
          if (this.ordonnancements[key].montant_ordonnancement.length >= 4) {
            // check if there's a space
            if (this.ordonnancements[key].montant_ordonnancement.includes(" ")) {
              var trimer = chaine.split(" ")
              chaine = ""
              for (let index = 0; index < trimer.length; index++) {
                chaine += trimer[index]
              }
              chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            } else {
              chaine = ""
              chaine = this.ordonnancements[key].montant_ordonnancement.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            }
          }
        }
        this.ordonnancements[key].montant_ordonnancement = chaine
      }
    },

    handleFileUpload(filename) {
      this.$refs["reference_arc"].disabled = true
      this.loadraf = true
      this.inputFiles[filename] = this.$refs[filename].files[0].name
      if (!this.inputFiles[filename].includes(" ")) {
        this.uploadfile = filename
        this.saveFile(this.$refs[filename].files[0])  
      }else{
        this.notif.message = "Vérifiez qu'il n'y a pas d'espaces dans le nom du fichier"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.$refs[filename].value=""
            this.inputFiles[filename]=""
          }.bind(this),
          3000
        )
      }
      
    },
    checkMontantActivite(id){
      this.activitesList.forEach(activite => {
        if((activite.id == id) && (activite.montant_definitif < this.form.montant_initial_marche)){
          this.is_inferieur=false
        }else{
          this.is_inferieur=true
        }
      })
    },
    verifierMontant(){
      var total=0
      this.ordonnancements.forEach(ordonnancement => {
        total += ordonnancement.montant_ordonnancement  
      })
      if (Number(total) > Number(this.form.montant_total)) {
        this.verifMontant=true
        this.notif.message = "le montant total des ordonnancements ne doit pas dépasser celui du marché"
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        this.loadrarc = false

        setTimeout(
          function() {
            this.notif.activated = ""
          }.bind(this),
          3000
        )
      }else{
        this.verifMontant=false
      }
    },
    onProgramme(id) {
      this.getProgramme(id)
    },
    onRubriques(evt) {
      if (this.rubriquesList) {
        var app = this
        this.rubriquesList.filter(function(proArr) {
          if (proArr.id == evt.target.value) {
            app.activitesList = proArr.activites
          }
        })
      }
    },
    /**
     * La fonction return la le programme dont l'id à été selectionné afin d'alimenter la liste des rubriques
     * @param value l'id du programme
     */
    getProgrameIdSelected(value) {
      this.getProgramme(value)
      this.removeSPan("programme")
    },
    /**
     * Recupére l'id de la rubrique selectionné et alimente la liste des activités de cette rubrique
     * @param value l'id de la rubrique
     */
    getRubriqueIdSelected(value) {
      if (this.rubriquesList) {
        const app = this
        this.rubriquesList.filter(function(proArr) {
          if (proArr.id == value) {
            app.activitesList = proArr.activites
          }
        })
      }
      this.removeSPan("rubrique")
    },
    getRubList(id) {
      this.getProgramme(id)
      if (this.assignProgramme.programme !== undefined) {
        this.rubriquesList = this.assignProgramme.programme.rubriques
      }
    },
    getActList(id) {
      this.rubriquesList.forEach(rubrique => {
        if (rubrique.id === id) {
          this.activitesList = rubrique.activites
        }
      })
      
    },
    goBack() {
      this.$router.push({ name: "Detail", params: { id: this.$route.params.id } })
    },
    /**
     * Cette fonction permet de retirer les messges lorsque le formulaire n'est pas complété
     * @param element l'element pour lequel le message est retirer
     */
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    },
    /**
     * Creation des espaces pour les extentions de fichiers
     * @returns {string}
     * @constructor
     */
    CreateEspaceBetweenFileExtentions() {
      return utils.formaFichierAccepteToString()
    },
    selectProgramme(){
      var filtre=""
      if (this.form.categorie_programme == "programme_investissement") {
        filtre=[{libelle:"categorie_programme",table:"programmes",nature:"egale",valeur:"programme_investissement",type:"caractere"}]
        this.ListProgrammes(filtre)
      }else{
        filtre=[{libelle:"categorie_programme",table:"programmes",nature:"egale",valeur:"programme_entretien_routier",type:"caractere"}]
        this.ListProgrammes(filtre)
      }

    }
  }
}
</script>
